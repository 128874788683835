var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"search-general"}},[_c('v-combobox',{ref:"combobox",staticClass:"mx-1 sbar",attrs:{"items":_vm.queryExamples,"placeholder":_vm.$t('home.search.placeholder'),"allow-overflow":"","rounded":"","solo":"","hide-details":"","flat":"","clearable":"","multiple":"","single-line":"","outlined":""},on:{"click:clear":function($event){return _vm.remove(-1)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.submit($event, _vm.random=false)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-btn',{attrs:{"title":_vm.$t('button.search'),"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.submit.apply(null, arguments)}}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1),_c('v-btn',{staticClass:"ml-1",attrs:{"id":"search-random","title":_vm.$t('search.random'),"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.submit($event, _vm.random=true)}}},[_c('v-icon',[_vm._v(" mdi-slot-machine-outline ")])],1)]},proxy:true},{key:"append",fn:function(){return [_c('v-select',{staticClass:"lang",attrs:{"items":_vm.langItems,"title":_vm.$t('home.search.lang'),"single-line":"","hide-details":"","solo":"","flat":""},on:{"change":_vm.updateLang},model:{value:(_vm.lang),callback:function ($$v) {_vm.lang=$$v},expression:"lang"}}),_c('ModalSearch',{on:{"clicked":_vm.hideMenu}})]},proxy:true},{key:"item",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
return _vm._l((item.entries),function(entry){return _c('v-chip',_vm._b({key:entry.value,staticClass:"mr-2"},'v-chip',attrs,false),[(entry.positive)?_c('v-btn',{staticClass:"ml-n2",attrs:{"icon":"","small":""}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_c('v-btn',{staticClass:"ml-n2",attrs:{"icon":"","small":""}},[_c('v-icon',[_vm._v("mdi-minus")])],1),(entry.type === 'idx')?_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"18px"}},[_vm._v(" mdi-file-image-outline ")]):_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"18px"}},[_vm._v(" mdi-file-document-outline ")]),(entry.type === 'idx')?_c('span',{attrs:{"title":entry.label}},[_vm._v(" "+_vm._s(entry.label)+" ")]):_c('span',{attrs:{"title":entry.value}},[_vm._v(" "+_vm._s(entry.value)+" ")])],1)})}},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var selected = ref.selected;
var item = ref.item;
var index = ref.index;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"min-width":"300","max-width":"300","offset-y":"","bottom":"","right":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"input-value":selected,"close":""},on:{"click:close":function($event){return _vm.remove(index)}}},'v-chip',attrs,false),on),[(item.positive)?_c('v-btn',{staticClass:"ml-n2",attrs:{"title":_vm.$t('home.search.query.negative'),"icon":"","small":""},on:{"click":function($event){return _vm.toggle(index)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_c('v-btn',{staticClass:"ml-n2",attrs:{"title":_vm.$t('home.search.query.positive'),"icon":"","small":""},on:{"click":function($event){return _vm.toggle(index)}}},[_c('v-icon',[_vm._v("mdi-minus")])],1),(item.type === 'idx')?_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"18px"}},[_vm._v(" mdi-file-image-outline ")]):_c('v-icon',{staticClass:"mr-1",staticStyle:{"font-size":"18px"}},[_vm._v(" mdi-file-document-outline ")]),(item.type === 'idx')?_c('span',{attrs:{"title":item.label}},[_vm._v(" "+_vm._s(item.label)+" ")]):_c('span',{attrs:{"title":item.value}},[_vm._v(" "+_vm._s(item.value)+" ")])],1)]}}],null,true)},[(item.type === 'idx' && item.preview)?_c('div',{staticStyle:{"margin-left":"auto","margin-right":"auto","height":"100%"}},[_c('ROISelector',{key:item.value,staticClass:"grey lighten-1",attrs:{"src":item.preview,"max-height":"200px","contain":""},on:{"update":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.updateROI.apply(void 0, [ index ].concat( argsArray ))}},model:{value:(item.roi),callback:function ($$v) {_vm.$set(item, "roi", $$v)},expression:"item.roi"}})],1):_vm._e(),(item.type === 'idx')?_c('div',[_c('Weights',{key:item.value,attrs:{"local":true},on:{"update":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.updateWeights.apply(void 0, [ index ].concat( argsArray ))}},model:{value:(item.weights),callback:function ($$v) {_vm.$set(item, "weights", $$v)},expression:"item.weights"}}),_c('div',{staticClass:"pa-6"},[_c('v-btn',{attrs:{"color":"accent","block":"","rounded":"","depressed":""},on:{"click":_vm.submitQueryStore}},[_vm._v(" "+_vm._s(_vm.$t("button.update"))+" ")])],1)],1):_vm._e()])]}}]),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }